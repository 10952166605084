import React from 'react';
import PropTypes from 'prop-types';

// components
import Card from '../../atoms/Card/Card';
import Picto from '../../atoms/Picto/Picto';

// styles
import styles from './CardDashboard.module.css';

// utils & misc
import cn from '../../../utils/cn';

function CardDashboard({
  title, picto, onClick, classNames, borderTopColored, texte, disabled, texteClassName,
}) {
  return (
    <Card
      borderTopColored={borderTopColored}
      onClick={onClick}
      className={cn([styles.card, styles.cardSecurityTheme].concat(classNames))}
      disabled={disabled}
    >
      <div className={styles.cardHeader}>
        <div className={styles.leftColumn}>
          <p className={cn([styles.titleCard])}>
            { title }
          </p>
        </div>
        <div className={styles.middleColumn} />
        <p className={cn([styles.texteCard, texteClassName])}>
          {texte}
        </p>
      </div>
      <div className={styles.rightColumn}>
        <Picto className={styles.picto} name={picto} />
      </div>
    </Card>
  );
}

CardDashboard.propTypes = {
  title: PropTypes.string.isRequired,
  picto: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  classNames: PropTypes.array.isRequired,
  texteClassName: PropTypes.string,
  borderTopColored: PropTypes.bool,
  texte: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
};

CardDashboard.defaultProps = {
  borderTopColored: false,
  disabled: false,
  onClick: () => {},
  texte: '',
  texteClassName: '',
};

export default CardDashboard;
